<div class="nav">
  <nav >
    <a routerLink="/" style="padding-left:45px" class="logo"><img src="./assets/images/basketball.svg" height="25" style="margin:0 10px 0 0; position:absolute; left:15px;top:14px" />Hoop Replays</a>
    <!-- <a routerLink="/games">Games</a>
    <a routerLink="/players">Players</a> -->
  </nav>
</div>

<div class="position-relative">
<div class="menu">
  <!-- <a routerLink="/"><fa-icon [icon]="['fas', 'house']"></fa-icon></a>
  <a routerLink="/players"><fa-icon [icon]="['fas', 'user']"></fa-icon></a>
  <fa-icon [icon]="['fas', 'coffee']"></fa-icon>
  <a routerLink="/games"
    ><fa-icon [icon]="['fas', 'calendar-days']"></fa-icon
  ></a> -->
</div>
<div class="content">
 
  <router-outlet></router-outlet>
</div>
</div>