import { Component } from '@angular/core';
import {DataService} from '../../services/data.service';
import {Game} from '../../models/game';
import { CommonModule } from '@angular/common';
import {GameCardComponent} from '../_components/game-card/game-card.component'
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {RouterModule} from '@angular/router';



@Component({
  selector: 'app-games',
  standalone: true,
  imports: [CommonModule, GameCardComponent, NgxDatatableModule, RouterModule],
  templateUrl: './games.component.html',
  styleUrl: './games.component.scss'
})
export class GamesComponent {

  games:Game[] = [];
  // rows = [
  //   { name: 'Austin', gender: 'Male', company: 'Swimlane' },
  //   { name: 'Dany', gender: 'Male', company: 'KFC' },
  //   { name: 'Molly', gender: 'Female', company: 'Burger King' }
  // ];
  columns = [
    { prop: 'id' }, 
    { name: 'name' }, 
    { prop: 'location' }, 
    { name: 'author' }, 
    { name: 'description' }, 
    { name: 'dateOfGame' }, 
    { name: 'date' }
  ];

  constructor(private dataService:DataService){

    this.dataService.gamesGet().subscribe((resp:any)=>{
      this.games = resp;
    })

  }

}
