import { Routes } from '@angular/router';
import {GamesComponent} from './pages/games/games.component'
import { LogoComponent } from './logo/logo.component';

export const routes: Routes = [
    {path:'',loadChildren:()=> import('./pages/access-code/access-code.module').then(m=>m.AccessCodeModule)},
    {path:'game',loadChildren:()=> import('./pages/game/game.module').then(m=>m.GameModule)},
    {path:'game-viewer',loadChildren:()=> import('./pages/game-viewer/game-viewer.module').then(m=>m.GameViewerModule)},
    {path:'players',loadChildren:()=> import('./pages/players/players.module').then(m=>m.PlayersModule)},
    {path:'locations',loadChildren:()=> import('./pages/locations/locations.module').then(m=>m.LocationsModule)},

    {path:'logo',component:LogoComponent},
    {path:'*',component:GamesComponent}
];
