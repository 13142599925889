
<!-- Generator: Adobe Illustrator 28.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 252 288" enable-background="new 0 0 252 288" xml:space="preserve">
<g>
    <rect fill="#090909" width="100%" height="100%"></rect>
	<g>
		<g>
			<path fill="#AA9774" d="M49.26,195.14c-1.87-0.46-2.33-0.78-2.33-1.55v-0.02c0-0.65,0.59-1.17,1.6-1.17
				c0.9,0,1.78,0.36,2.67,1.01l0.91-1.3c-0.99-0.8-2.12-1.22-3.55-1.22c-1.94,0-3.34,1.16-3.34,2.83v0.03
				c0,1.81,1.17,2.41,3.26,2.92c1.82,0.42,2.22,0.8,2.22,1.5v0.03c0,0.75-0.68,1.24-1.74,1.24c-1.22,0-2.15-0.47-3.1-1.29
				l-1.03,1.24c1.17,1.04,2.59,1.56,4.09,1.56c2.05,0,3.49-1.09,3.49-2.92v-0.03C52.4,196.38,51.33,195.65,49.26,195.14z"/>
			<path fill="#AA9774" d="M68.94,191.02l-2.33,7.27l-2.41-7.3h-1.47l-2.39,7.3l-2.35-7.27h-1.86l3.4,9.87h1.5l2.39-7.04l2.41,7.04
				h1.48l3.42-9.87H68.94z"/>
			<path fill="#AA9774" d="M77.02,199.28v-2.62h4.92v-1.55h-4.92v-2.54h5.56v-1.55h-7.27v9.81h7.33v-1.55H77.02z"/>
			<path fill="#AA9774" d="M89.21,199.28v-2.62h4.92v-1.55h-4.92v-2.54h5.54v-1.55h-7.27v9.81h7.35v-1.55H89.21z"/>
			<path fill="#AA9774" d="M98.97,191.02v1.6h3.11v8.21h1.73v-8.21h3.11v-1.6H98.97z"/>
			<path fill="#AA9774" d="M122.18,191.02h-3.65v9.81h3.65c3.08,0,5.21-2.15,5.21-4.9v-0.03
				C127.4,193.14,125.26,191.02,122.18,191.02z M125.59,195.96c0,1.91-1.35,3.29-3.4,3.29h-1.92v-6.66h1.92
				c2.05,0,3.4,1.42,3.4,3.34V195.96z"/>
			<path fill="#AA9774" d="M133.99,199.28v-2.62h4.92v-1.55h-4.92v-2.54h5.56v-1.55h-7.28v9.81h7.35v-1.55H133.99z"/>
			<path fill="#AA9774" d="M146.18,199.25v-8.23h-1.73v9.81h6.88v-1.58H146.18z"/>
			<path fill="#AA9774" d="M156.02,191.02v9.81h1.71v-9.81H156.02z"/>
			<path fill="#AA9774" d="M167.72,195.35v1.5h2.51v1.82c-0.6,0.46-1.45,0.75-2.38,0.75c-1.99,0-3.32-1.48-3.32-3.5v-0.03
				c0-1.87,1.37-3.45,3.18-3.45c1.25,0,1.99,0.41,2.74,1.04l1.11-1.3c-1.01-0.85-2.07-1.32-3.78-1.32c-2.95,0-5.05,2.31-5.05,5.07
				v0.03c0,2.85,2.02,5.03,5.08,5.03c1.74,0,3.13-0.7,4.09-1.53v-4.11H167.72z"/>
			<path fill="#AA9774" d="M183.33,191.02v4.07h-4.68v-4.07h-1.73v9.81h1.73v-4.14h4.68v4.14h1.73v-9.81H183.33z"/>
			<path fill="#AA9774" d="M189.66,191.02v1.6h3.11v8.21h1.73v-8.21h3.11v-1.6H189.66z"/>
			<path fill="#AA9774" d="M205.81,195.14c-1.89-0.46-2.33-0.78-2.33-1.55v-0.02c0-0.65,0.59-1.17,1.6-1.17
				c0.9,0,1.78,0.36,2.66,1.01l0.93-1.3c-0.99-0.8-2.13-1.22-3.55-1.22c-1.95,0-3.36,1.16-3.36,2.83v0.03
				c0,1.81,1.17,2.41,3.26,2.92c1.82,0.42,2.22,0.8,2.22,1.5v0.03c0,0.75-0.67,1.24-1.73,1.24c-1.22,0-2.17-0.47-3.1-1.29
				l-1.04,1.24c1.17,1.04,2.59,1.56,4.09,1.56c2.05,0,3.5-1.09,3.5-2.92v-0.03C208.95,196.38,207.88,195.65,205.81,195.14z"/>
		</g>
		<g>
			<g>
				<path fill="#AA9774" d="M139.02,57.35c2.56,1.08,4.98,2.52,7.16,4.25c0.26,0.2,0.63,0.03,0.63-0.3v-6.75
					c0-1.99-1.14-3.82-2.95-4.67c-10.52-4.97-23.24-4.97-33.76,0.01c-1.79,0.85-2.92,2.65-2.92,4.63c0,7.08,0.01,22.27,0.02,27.82
					c0,1.34,0.53,2.63,1.47,3.58c9.76,9.88,28.32,28.66,37.48,37.93c0.24,0.25,0.66,0.08,0.66-0.27c0.01-3.13-0.01-8.37-0.01-11.36
					c0-0.36-0.14-0.7-0.4-0.95L115.98,81.2c-0.23-0.22-0.35-0.53-0.35-0.84l0-10.49c0-0.43,0.51-0.64,0.81-0.34
					c3.94,3.98,21.19,21.44,29.7,30.06c0.24,0.25,0.67,0.08,0.67-0.27c0-2.42,0.03-8.77,0-10.64c0-0.33-0.13-0.65-0.37-0.89
					c-8.54-8.64-21.33-21.58-29.96-30.32c-0.24-0.25-0.15-0.66,0.18-0.78C123.81,54.14,132.04,54.36,139.02,57.35z"/>
			</g>
			<g>
				<path fill="#AA9774" d="M131.63,25.68l-4.29-4.29c-0.19-0.19-0.5-0.19-0.68,0l-4.29,4.29c-1.19,1.19-1.92,2.82-1.92,4.63
					c0,3.62,2.93,6.55,6.55,6.55c3.62,0,6.55-2.93,6.55-6.55C133.55,28.5,132.82,26.86,131.63,25.68z"/>
				<path fill="#AA9774" d="M111.86,31.32h-4.19c-0.27,0-0.48,0.22-0.48,0.48V36c0,2.58,2.1,4.68,4.68,4.68
					c2.58,0,4.68-2.09,4.68-4.68C116.54,33.42,114.45,31.32,111.86,31.32z"/>
				<path fill="#AA9774" d="M146.8,35.82v-4.02c0-0.27-0.22-0.48-0.48-0.48h-4.2v0c-2.58,0.01-4.66,2.1-4.66,4.68
					c0,2.58,2.09,4.68,4.68,4.68c2.58,0,4.68-2.09,4.68-4.68C146.82,35.94,146.8,35.88,146.8,35.82z"/>
			</g>
		</g>
		<g>
			<path fill="#AA9774" d="M182.62,142.79c-0.8-0.8-1.87-1.24-3-1.24c-1.13,0-2.2,0.44-3,1.24c-1.65,1.65-1.65,4.35,0,6
				c0.04,0.04,2.43,2.41,2.43,2.41c0.16,0.16,0.37,0.24,0.58,0.24c0.21,0,0.42-0.08,0.59-0.24l2.83-2.84h-0.05
				C184.26,146.71,184.13,144.31,182.62,142.79z"/>
			<path fill="#AA9774" d="M60.48,167.43c-0.36-0.18-0.79-0.15-1.13,0.09c-1.22,0.89-2.62,1.36-4.17,1.39H43.88
				c-0.32,0-0.59-0.26-0.59-0.59v-5.21c0-0.32,0.26-0.59,0.59-0.59h9.08c0.6,0,1.08-0.48,1.08-1.08v-4.74c0-0.6-0.48-1.08-1.08-1.08
				h-9.08c-0.32,0-0.59-0.26-0.59-0.59v-4.98c0-0.32,0.26-0.59,0.59-0.59h11.11c1.55,0.04,2.95,0.51,4.16,1.4
				c0.32,0.23,0.72,0.27,1.08,0.08c0.37-0.19,0.6-0.56,0.6-0.97l0-6.34c0-0.59-0.48-1.07-1.08-1.07H34.94
				c-0.41,0-0.78,0.23-0.96,0.61c-0.18,0.37-0.14,0.81,0.12,1.13c1.37,1.75,1.53,3.25,1.55,3.65l0,22.45
				c-0.02,0.42-0.17,1.91-1.55,3.66c-0.25,0.32-0.3,0.76-0.12,1.13c0.18,0.37,0.55,0.61,0.96,0.61h25.05c0.6,0,1.08-0.48,1.08-1.08
				v-6.35C61.07,167.98,60.85,167.61,60.48,167.43z"/>
			<path fill="#AA9774" d="M126.06,144.31c0.25-0.32,0.3-0.76,0.12-1.13c-0.18-0.37-0.55-0.61-0.96-0.61h-9.11
				c-0.41,0-0.78,0.23-0.97,0.61c-0.18,0.37-0.14,0.81,0.12,1.13c1.4,1.78,1.54,3.28,1.55,3.69v22.37c-0.01,0.42-0.15,1.92-1.55,3.7
				c-0.25,0.32-0.3,0.76-0.12,1.13c0.18,0.37,0.55,0.61,0.97,0.61h9.11c0.41,0,0.78-0.23,0.96-0.61c0.18-0.37,0.14-0.81-0.12-1.13
				c-1.4-1.78-1.54-3.28-1.55-3.69V148C124.52,147.58,124.67,146.08,126.06,144.31z"/>
			<path fill="#AA9774" d="M164.29,167.43c-0.36-0.18-0.79-0.15-1.12,0.09c-1.22,0.89-2.62,1.36-4.17,1.39h-11.31
				c-0.32,0-0.59-0.26-0.59-0.59v-5.21c0-0.32,0.26-0.59,0.59-0.59h9.08c0.6,0,1.08-0.48,1.08-1.08v-4.74c0-0.6-0.48-1.08-1.08-1.08
				h-9.08c-0.32,0-0.59-0.26-0.59-0.59v-4.98c0-0.32,0.26-0.59,0.59-0.59h11.11c1.54,0.04,2.94,0.51,4.16,1.4
				c0.32,0.23,0.72,0.27,1.08,0.08c0.37-0.19,0.6-0.56,0.6-0.97l0-6.34c0-0.59-0.48-1.07-1.08-1.07h-24.81
				c-0.41,0-0.78,0.23-0.96,0.61c-0.18,0.37-0.14,0.81,0.12,1.13c1.37,1.75,1.53,3.25,1.55,3.65l0,22.45
				c-0.02,0.42-0.17,1.91-1.55,3.66c-0.25,0.32-0.3,0.76-0.12,1.13c0.18,0.37,0.55,0.61,0.96,0.61h25.05c0.59,0,1.08-0.48,1.08-1.08
				v-6.35C164.87,167.98,164.65,167.61,164.29,167.43z"/>
			<path fill="#AA9774" d="M103.9,142.57h-8.67c-0.41,0-0.78,0.23-0.96,0.61c-0.18,0.38-0.14,0.82,0.12,1.15
				c0.47,0.57,0.94,1.47,0.56,2.53l-6.26,16.84c-0.11,0.28-0.36,0.38-0.55,0.38c-0.2,0-0.45-0.1-0.55-0.38l-6.26-16.85
				c-0.31-0.83-0.13-1.68,0.55-2.53c0.26-0.32,0.32-0.76,0.14-1.14c-0.18-0.38-0.55-0.61-0.96-0.61H72.2c-0.42,0-0.8,0.24-0.98,0.62
				c-0.18,0.38-0.11,0.81,0.15,1.13c1.2,1.48,1.86,3.16,1.87,3.18l9.51,23.67c0.62,1.6,0.26,2.38,0.04,2.87
				c-0.03,0.04-0.05,0.09-0.07,0.14l-0.02,0.04c-0.02,0.03-0.05,0.08-0.07,0.14c-0.16,0.48-0.14,0.93,0.08,1.23
				c0.14,0.21,0.45,0.45,1.06,0.45h8.52c0.65,0,0.95-0.27,1.09-0.5c0.23-0.38,0.21-0.93-0.07-1.48c-0.24-0.51-0.59-1.29,0.02-2.87
				l9.51-23.71c0,0,0.16-0.42,0.48-1.02c0.45-0.85,0.9-1.55,1.39-2.16c0.27-0.32,0.33-0.75,0.15-1.13
				C104.7,142.81,104.33,142.57,103.9,142.57z"/>
			<path fill="#AA9774" d="M207.56,155.72L207.56,155.72c-5.72-1.47-6.88-2.15-6.88-4.07v-0.09c0-1.58,1.56-2.61,3.97-2.61
				c1.64,0,3.43,0.46,5.29,1.36c0.71,0.37,1.27,1.17,1.61,1.78c0.19,0.34,0.53,0.55,0.91,0.56c0.02,0,0.03,0,0.05,0
				c0.35,0,0.68-0.17,0.88-0.47l3.87-5.72c0.24-0.35,0.25-0.8,0.04-1.18c-0.21-0.35-0.58-0.56-0.98-0.53
				c-0.97,0.06-1.84-0.14-2.63-0.62c-0.11-0.06-0.21-0.1-0.33-0.15c-2.63-1.26-5.52-1.89-8.6-1.89c-6.89,0-11.71,4.16-11.71,10.12
				v0.09c0,6.88,4.78,8.65,11.04,10.25c5.52,1.41,6.41,2.32,6.41,3.93v0.09c0,1.78-1.67,2.84-4.48,2.84c-2.31,0-4.49-0.61-6.68-1.86
				c-0.69-0.41-1.22-1.2-1.54-1.79c-0.17-0.32-0.49-0.53-0.85-0.57c-0.36-0.03-0.7,0.11-0.93,0.38l-4.51,5.4
				c-0.27,0.33-0.33,0.77-0.15,1.15c0.19,0.39,0.57,0.61,1.01,0.62c1.08-0.04,2.07,0.22,2.89,0.72c0.05,0.03,0.09,0.05,0.16,0.07
				c3.18,1.79,6.79,2.74,10.45,2.74c7.57,0,12.27-3.98,12.27-10.4v-0.09C218.13,159.72,213.9,157.38,207.56,155.72z"/>
		</g>
	</g>
	<g>
		<path fill="#AA9774" d="M45.17,237.87c1.11,0.61,2.23,1.21,3.35,1.79c0.83,0.43,1.37,0.98,1.59,1.58
			c0.17,0.48,0.15,0.95-0.08,1.42c0,0.01-0.01,0.01-0.01,0.02c-0.39,0.77-1.05,0.98-1.72,0.99c0.71,0.74,1.01,1.46,0.55,2.39
			c0,0.01-0.01,0.01-0.01,0.02c-0.62,1.26-2.07,1.33-3.78,0.44c-1.2-0.62-2.39-1.26-3.58-1.92
			C42.71,242.35,43.94,240.11,45.17,237.87z M46.74,242.23c0.72,0.37,1.28,0.39,1.54-0.11c0-0.01,0.01-0.01,0.01-0.02
			c0.23-0.45-0.02-0.89-0.69-1.25c-0.53-0.28-1.06-0.56-1.58-0.84c-0.26,0.48-0.51,0.95-0.77,1.43
			C45.74,241.7,46.24,241.97,46.74,242.23z M45.74,245.19c0.73,0.38,1.29,0.37,1.55-0.14c0-0.01,0.01-0.01,0.01-0.02
			c0.24-0.46,0.01-0.93-0.83-1.37c-0.63-0.33-1.26-0.66-1.88-1c-0.27,0.5-0.53,0.99-0.8,1.49
			C44.44,244.51,45.09,244.85,45.74,245.19z"/>
		<path fill="#AA9774" d="M58.34,244.32c0.5,0.22,1,0.44,1.5,0.66c0.04,2.82,0.13,5.66,0.28,8.5c-0.6-0.24-1.2-0.49-1.79-0.74
			c-0.02-0.63-0.03-1.26-0.04-1.88c-1.09-0.46-2.17-0.94-3.25-1.42c-0.47,0.42-0.94,0.84-1.41,1.25c-0.58-0.26-1.15-0.53-1.73-0.8
			C54.09,248.06,56.24,246.21,58.34,244.32z M58.27,249.22c-0.01-0.91-0.02-1.81-0.02-2.72c-0.67,0.61-1.33,1.23-2.01,1.83
			C56.92,248.64,57.59,248.93,58.27,249.22z"/>
		<path fill="#AA9774" d="M67.38,248.08c0.55,0.21,1.11,0.41,1.66,0.61c-0.38,1.05-0.76,2.1-1.15,3.15
			c1.42-0.67,2.83-1.36,4.22-2.07c0.67,0.23,1.35,0.46,2.02,0.68c-1.37,0.69-2.76,1.36-4.17,2.02c0.61,1.77,1.25,3.55,1.93,5.32
			c-0.7-0.23-1.4-0.46-2.1-0.7c-0.47-1.31-0.92-2.62-1.36-3.94c-0.4,0.18-0.81,0.36-1.21,0.54c-0.27,0.74-0.53,1.47-0.8,2.21
			c-0.58-0.21-1.16-0.42-1.74-0.64C65.58,252.87,66.48,250.48,67.38,248.08z"/>
		<path fill="#AA9774" d="M78.65,251.87c0.56,0.17,1.13,0.33,1.69,0.49c-0.71,2.46-1.41,4.92-2.12,7.38
			c-0.59-0.17-1.18-0.34-1.77-0.52C77.19,256.77,77.92,254.32,78.65,251.87z"/>
		<path fill="#AA9774" d="M85.57,253.78c0.52,0.13,1.05,0.26,1.57,0.39c0.84,1.82,1.72,3.64,2.63,5.46
			c0.34-1.54,0.68-3.08,1.01-4.61c0.56,0.12,1.13,0.25,1.69,0.36c-0.52,2.5-1.05,5.01-1.57,7.51c-0.51-0.11-1.02-0.22-1.53-0.33
			c-0.97-1.88-1.9-3.76-2.79-5.65c-0.38,1.58-0.77,3.16-1.15,4.74c-0.59-0.14-1.17-0.29-1.76-0.44
			C84.32,258.74,84.95,256.26,85.57,253.78z"/>
		<path fill="#AA9774" d="M96.67,260.12c0-0.01,0-0.01,0-0.02c0.39-2.15,2.44-3.59,4.81-3.22c1.42,0.22,2.22,0.7,2.95,1.49
			c-0.42,0.37-0.85,0.75-1.28,1.12c-0.55-0.58-1.05-0.94-1.96-1.08c-1.26-0.19-2.45,0.71-2.67,1.99c0,0.01,0,0.01,0,0.02
			c-0.24,1.38,0.62,2.57,2.06,2.8c0.65,0.1,1.26,0.03,1.75-0.2c0.05-0.36,0.1-0.72,0.16-1.09c-0.6-0.09-1.19-0.18-1.79-0.27
			c0.07-0.48,0.15-0.96,0.22-1.44c1.16,0.18,2.32,0.35,3.48,0.5c-0.15,1.1-0.29,2.2-0.44,3.3c-0.93,0.55-2.17,0.93-3.68,0.7
			C97.72,264.33,96.26,262.34,96.67,260.12z"/>
		<path fill="#AA9774" d="M118.65,263.42c-0.96-1.6-1.9-3.21-2.81-4.82c0.68,0.05,1.36,0.09,2.04,0.13
			c0.57,1.06,1.16,2.12,1.75,3.17c0.7-1,1.39-2,2.06-3c0.66,0.02,1.32,0.04,1.98,0.05c-1.05,1.52-2.13,3.02-3.23,4.52
			c-0.04,1.02-0.08,2.04-0.12,3.06c-0.61-0.02-1.21-0.05-1.82-0.08C118.55,265.43,118.6,264.42,118.65,263.42z"/>
		<path fill="#AA9774" d="M127.12,262.85c0-0.01,0-0.01,0-0.02c0.01-2.18,1.8-3.96,4.23-4.01c2.44-0.06,4.27,1.61,4.39,3.8
			c0,0.01,0,0.01,0,0.02c0.11,2.18-1.66,4.06-4.23,4.12C128.94,266.81,127.1,265.03,127.12,262.85z M133.87,262.73
			c0-0.01,0-0.01,0-0.02c-0.05-1.31-1.1-2.37-2.51-2.34c-1.41,0.03-2.39,1.11-2.38,2.43c0,0.01,0,0.01,0,0.02
			c0.01,1.31,1.05,2.41,2.51,2.37C132.95,265.16,133.93,264.04,133.87,262.73z"/>
		<path fill="#AA9774" d="M140.69,262.87c-0.11-1.46-0.22-2.92-0.33-4.37c0.58-0.04,1.16-0.09,1.74-0.14
			c0.12,1.44,0.25,2.88,0.37,4.32c0.11,1.24,0.83,1.83,1.93,1.73c1.1-0.11,1.7-0.8,1.57-2.01c-0.15-1.46-0.31-2.91-0.47-4.37
			c0.58-0.06,1.16-0.13,1.74-0.19c0.17,1.43,0.34,2.87,0.5,4.3c0.28,2.31-0.99,3.61-3.22,3.83
			C142.3,266.18,140.86,265.13,140.69,262.87z"/>
		<path fill="#AA9774" d="M159.11,256.03c0.57-0.11,1.15-0.22,1.72-0.33c0.2,0.99,0.4,1.99,0.59,2.98c1.08-0.21,2.15-0.44,3.22-0.67
			c-0.22-0.99-0.43-1.98-0.65-2.97c0.57-0.12,1.14-0.25,1.71-0.38c0.57,2.49,1.15,4.99,1.72,7.48c-0.6,0.14-1.19,0.27-1.79,0.4
			c-0.22-1-0.44-2.01-0.66-3.01c-1.08,0.24-2.17,0.46-3.25,0.68c0.2,1.01,0.4,2.01,0.6,3.02c-0.6,0.12-1.2,0.24-1.8,0.35
			C160.06,261.06,159.58,258.54,159.11,256.03z"/>
		<path fill="#AA9774" d="M173.51,252.6c0.52-0.15,1.04-0.29,1.56-0.45c1.82,2.16,3.68,4.28,5.59,6.39
			c-0.61,0.19-1.23,0.38-1.84,0.56c-0.41-0.48-0.82-0.95-1.22-1.43c-1.12,0.33-2.24,0.65-3.37,0.96c-0.09,0.62-0.18,1.24-0.28,1.86
			c-0.6,0.16-1.21,0.32-1.81,0.48C172.65,258.19,173.11,255.39,173.51,252.6z M176.56,256.43c-0.58-0.69-1.16-1.39-1.73-2.09
			c-0.12,0.9-0.24,1.79-0.37,2.69C175.16,256.83,175.86,256.63,176.56,256.43z"/>
		<path fill="#AA9774" d="M182.81,249.77c1.04-0.36,2.07-0.72,3.1-1.1c1.8-0.66,3.27-0.07,3.84,1.39c0,0.01,0.01,0.01,0.01,0.02
			c0.64,1.66-0.42,3.06-2.18,3.69c-0.44,0.16-0.87,0.31-1.31,0.47c0.26,0.72,0.51,1.45,0.77,2.17c-0.58,0.21-1.16,0.41-1.74,0.61
			C184.47,254.61,183.64,252.19,182.81,249.77z M187.12,252.35c0.85-0.31,1.17-0.97,0.94-1.58c0-0.01,0-0.01-0.01-0.02
			c-0.27-0.71-0.93-0.89-1.77-0.59c-0.43,0.16-0.87,0.31-1.3,0.47c0.26,0.74,0.52,1.47,0.78,2.21
			C186.22,252.67,186.67,252.51,187.12,252.35z"/>
		<path fill="#AA9774" d="M193.22,245.82c1.02-0.42,2.03-0.86,3.04-1.3c1.76-0.78,3.28-0.29,3.94,1.13c0,0.01,0.01,0.01,0.01,0.02
			c0.75,1.61-0.23,3.09-1.95,3.84c-0.43,0.19-0.85,0.37-1.28,0.56c0.3,0.71,0.61,1.41,0.91,2.12c-0.57,0.24-1.14,0.49-1.71,0.73
			C195.19,250.54,194.2,248.18,193.22,245.82z M197.71,248.1c0.83-0.37,1.11-1.05,0.84-1.65c0-0.01-0.01-0.01-0.01-0.02
			c-0.32-0.69-0.98-0.83-1.81-0.47c-0.42,0.19-0.85,0.37-1.27,0.55c0.31,0.72,0.62,1.44,0.93,2.16
			C196.83,248.49,197.27,248.3,197.71,248.1z"/>
		<path fill="#AA9774" d="M207.48,244.32c-1.66-0.89-3.3-1.8-4.92-2.73c0.63-0.31,1.25-0.62,1.88-0.93
			c1.04,0.62,2.09,1.24,3.15,1.85c0.12-1.22,0.24-2.44,0.33-3.66c0.6-0.32,1.2-0.65,1.8-0.97c-0.18,1.85-0.38,3.71-0.62,5.57
			c0.47,0.9,0.95,1.8,1.42,2.71c-0.55,0.29-1.11,0.58-1.66,0.87C208.4,246.11,207.94,245.21,207.48,244.32z"/>
	</g>
	<g>
		<path fill="#AA9774" d="M38.72,103.04c0.12-0.52,0.25-1.04,0.37-1.56c0.93,0.15,1.78,0.1,2.62-0.34c0.65-0.34,0.93-0.79,0.72-1.19
			c0-0.01-0.01-0.01-0.01-0.02c-0.2-0.38-0.55-0.45-1.93-0.09c-1.67,0.43-2.82,0.58-3.56-0.75c0-0.01-0.01-0.01-0.01-0.02
			c-0.67-1.22-0.02-2.63,1.52-3.44c1.1-0.58,2.21-0.75,3.25-0.58c-0.1,0.52-0.19,1.05-0.29,1.57c-0.88-0.08-1.67,0-2.3,0.34
			c-0.63,0.33-0.82,0.77-0.65,1.1c0,0.01,0.01,0.01,0.01,0.02c0.23,0.45,0.63,0.41,2.07,0.05c1.69-0.43,2.8-0.35,3.4,0.85
			c0,0.01,0.01,0.01,0.01,0.02c0.68,1.36-0.04,2.67-1.6,3.49C41.27,103.05,39.98,103.29,38.72,103.04z"/>
		<path fill="#AA9774" d="M46.34,91.83c0.56-0.26,1.13-0.52,1.69-0.78c1.06,2.33,2.11,4.66,3.17,6.99
			c-0.54,0.25-1.08,0.49-1.62,0.75C48.5,96.47,47.42,94.15,46.34,91.83z"/>
		<path fill="#AA9774" d="M53.28,88.77c0.53-0.22,1.05-0.44,1.58-0.65c1.81,0.96,3.6,1.96,5.35,2.97c-0.56-1.47-1.12-2.94-1.68-4.42
			c0.57-0.22,1.14-0.43,1.71-0.64c0.88,2.4,1.77,4.8,2.65,7.2c-0.47,0.17-0.94,0.35-1.41,0.53c-1.79-1.06-3.61-2.09-5.47-3.1
			c0.61,1.51,1.22,3.02,1.83,4.52c-0.54,0.22-1.08,0.44-1.62,0.67C55.25,93.49,54.27,91.13,53.28,88.77z"/>
		<path fill="#AA9774" d="M66.41,87.96c0-0.01-0.01-0.01-0.01-0.02c-0.7-2.06,0.43-4.34,2.9-5.1c1.51-0.47,2.55-0.27,3.49,0.19
			c-0.26,0.5-0.51,1.01-0.76,1.52c-0.76-0.33-1.49-0.46-2.28-0.22c-1.34,0.42-1.97,1.75-1.56,3c0,0.01,0,0.01,0.01,0.02
			c0.41,1.25,1.66,2,2.98,1.59c0.88-0.27,1.32-0.77,1.78-1.48c0.47,0.24,0.93,0.48,1.39,0.72c-0.56,1.04-1.29,1.81-2.76,2.26
			C69.34,91.13,67.13,90.04,66.41,87.96z"/>
		<path fill="#AA9774" d="M77.42,80.66c2.03-0.53,4.07-1.02,6.11-1.47c0.11,0.49,0.22,0.98,0.33,1.47
			c-1.44,0.32-2.88,0.66-4.31,1.02c0.13,0.5,0.25,1.01,0.38,1.51c1.25-0.31,2.5-0.61,3.76-0.89c0.11,0.49,0.22,0.98,0.33,1.47
			c-1.24,0.28-2.49,0.58-3.73,0.88c0.13,0.52,0.26,1.04,0.39,1.56c1.42-0.35,2.83-0.68,4.25-1c0.11,0.49,0.22,0.98,0.33,1.47
			c-1.98,0.44-3.95,0.91-5.91,1.42C78.7,85.62,78.06,83.14,77.42,80.66z"/>
		<path fill="#AA9774" d="M170.98,87.91c1.05-0.43,2.11-0.84,3.18-1.25c1.2-0.46,1.71-0.74,1.9-1.34c0.19-0.62-0.15-1.09-0.77-1.28
			c-0.61-0.19-1.13,0-1.91,0.5c-0.32-0.42-0.64-0.85-0.96-1.27c1.06-0.77,2-1.08,3.43-0.64c1.66,0.51,2.47,1.75,2.03,3.12
			c0,0.01,0,0.01-0.01,0.02c-0.4,1.22-1.26,1.6-2.95,2.18c-0.52,0.18-1.03,0.36-1.54,0.54c1.12,0.34,2.24,0.69,3.36,1.05
			c-0.15,0.46-0.3,0.92-0.45,1.39c-1.88-0.61-3.78-1.18-5.67-1.72C170.74,88.77,170.86,88.34,170.98,87.91z"/>
		<path fill="#AA9774" d="M182.01,88.82c0-0.01,0-0.01,0.01-0.02c0.73-2.1,2.74-3.25,4.84-2.46c2.09,0.79,2.85,2.95,2.01,5.01
			c0,0.01,0,0.01-0.01,0.02c-0.83,2.07-2.82,3.14-4.82,2.39C182.04,93.01,181.27,90.93,182.01,88.82z M187.12,90.72
			c0-0.01,0.01-0.01,0.01-0.02c0.51-1.3,0.19-2.55-0.83-2.94c-1.03-0.39-2.05,0.33-2.53,1.65c0,0.01,0,0.01-0.01,0.02
			c-0.48,1.32-0.17,2.54,0.84,2.92C185.61,92.72,186.62,92.01,187.12,90.72z"/>
		<path fill="#AA9774" d="M192.15,95.45c1.11-0.28,2.22-0.56,3.34-0.82c1.26-0.3,1.81-0.5,2.07-1.08c0.27-0.59-0.01-1.1-0.61-1.37
			c-0.59-0.27-1.13-0.15-1.98,0.24c-0.26-0.46-0.53-0.93-0.8-1.39c1.17-0.62,2.14-0.81,3.52-0.19c1.6,0.72,2.26,2.07,1.64,3.37
			c0,0.01-0.01,0.01-0.01,0.02c-0.55,1.16-1.46,1.43-3.23,1.78c-0.54,0.11-1.08,0.22-1.61,0.33c1.08,0.48,2.16,0.98,3.24,1.49
			c-0.21,0.44-0.41,0.88-0.62,1.32c-1.81-0.86-3.64-1.68-5.47-2.47C191.8,96.27,191.97,95.86,192.15,95.45z"/>
		<path fill="#AA9774" d="M201.91,100.01c1.13-0.21,2.26-0.41,3.41-0.6c1.28-0.22,1.84-0.39,2.14-0.95c0.31-0.57,0.05-1.1-0.53-1.41
			c-0.58-0.31-1.13-0.22-2,0.12c-0.24-0.48-0.48-0.96-0.72-1.44c1.21-0.54,2.19-0.67,3.54,0.04c1.57,0.83,2.14,2.21,1.45,3.47
			c0,0.01-0.01,0.01-0.01,0.02c-0.62,1.13-1.55,1.33-3.35,1.57c-0.55,0.07-1.1,0.15-1.64,0.23c1.06,0.55,2.11,1.12,3.16,1.7
			c-0.23,0.43-0.47,0.85-0.7,1.28c-1.77-0.97-3.55-1.91-5.35-2.82C201.51,100.81,201.71,100.41,201.91,100.01z"/>
	</g>
</g>
</svg>
