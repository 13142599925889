import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import {YOUTUBE_PLAYER_CONFIG} from '@angular/youtube-player';


import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch   } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';



export const appConfig: ApplicationConfig = {
 
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes), 
    provideClientHydration(),
    provideHttpClient(withFetch()),
    {
      provide: YOUTUBE_PLAYER_CONFIG,
      useValue: {
        loadApi: true,      
         DEFAULT_PLAYER_WIDTH:"100%",
        DEFAULT_PLAYER_HEIGHT:"100%",
      
          rel:0,
          iv_load_policy:3,
          modestbranding:1
       
      },
     
      
    }, 
    provideAnimationsAsync()
     
  ]
};
