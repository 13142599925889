<div class="container-fluid">
<div class="row">
    <div class="col"><h1>Hoop Replays > Games</h1>

        {{games|json}}
        
        <div *ngFor="let game of games" class="game-list">
            <app-game-card [game]="game"></app-game-card>
        </div>
        </div>
</div>

</div>

<div *ngIf="games!=null">
    <ngx-datatable 
    class="material"
    [limit]="50"
    [rows]="games" 
   
    [columnMode]="'force'" 
    [headerHeight]="50" 
    [footerHeight]="50"
    [rowHeight]="50" 
    [scrollbarV]="false" >

    <ngx-datatable-column name="View"><ng-template let-row="row" ngx-datatable-cell-template><a routerLink="/game/{{row.game_Id}}">View Game</a></ng-template></ngx-datatable-column>
    <ngx-datatable-column name="ID" prop="game_Id"><ng-template let-row="row" ngx-datatable-cell-template>{{row.game_Id}}</ng-template></ngx-datatable-column>
    <ngx-datatable-column name="Date" prop="dateOfGame"><ng-template let-row="row" ngx-datatable-cell-template>{{row.dateOfGame?(row.dateOfGame|date:'yyyy-MM-dd'):""}}</ng-template></ngx-datatable-column>
    <ngx-datatable-column name="Name" prop="name"><ng-template let-row="row" ngx-datatable-cell-template>{{ row.name }}</ng-template></ngx-datatable-column>
      

</ngx-datatable>
  </div>